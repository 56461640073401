import React from "react";

import { CssBaseline } from "@mui/material";
import { Helmet } from "react-helmet";

// @ts-expect-error Not a module
import logo from "../../assets/logo.ico";

function Layout({ children }: { children: JSX.Element }) {
  return (
    <div>
      <Helmet>
        <meta name="icon" href={logo} />
        <link
          href="https://fonts.googleapis.com/css?family=Poppins"
          rel="stylesheet"
        ></link>
      </Helmet>
      <CssBaseline />
      {children}
    </div>
  );
}

export default Layout;
